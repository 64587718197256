import '../css/main.scss';
import '../css/about.scss';
import 'bootstrap/js/dist/collapse' // Required by collapsable main menù

if (navigator.share) {
  const shareButton = document.querySelector('button[data-role="social-share"]');
  const title = document.querySelector('title');
  const description = document.querySelector('meta[name="description"]');
  const canonicalUrl = document.querySelector('link[rel=canonical]');

  if (title === null || canonicalUrl === null || shareButton === null) {
    return;
  }

  shareButton.classList.remove('d-none');

  shareButton.addEventListener('click', () => {
    navigator.share({
      title: title.text,
      text: description && description.content,
      url: canonicalUrl.href,
    })
      .then(() => console.log('Successful share'))
      .catch((error) => console.log('Error sharing', error));
  });
}
